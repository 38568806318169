








import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-unpin',
})
export default class IconUnpin extends Vue {
}

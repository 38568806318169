<template>
  <svg class="svg-icon" :class="{'svg-icon__active': active }" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path class="circle"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"/>
    <mask class="figure" id="path-2-inside-1">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.6857 23.999H11.5591C11.5802 23.9993 11.6013 23.9994 11.6224 23.9994C11.6435 23.9994 11.6646 23.9993 11.6857 23.999ZM17.5866 17.1828H5.65836V17.2324C5.65825 17.216 5.6582 17.1996 5.6582 17.1832C5.6582 13.4187 8.32846 10.3669 11.6224 10.3669C14.9162 10.3669 17.5864 13.4185 17.5866 17.1828Z"/>
    </mask>
    <path class="figure2"
          d="M11.6857 23.999L11.6936 24.6656L11.6857 23.3323V23.999ZM11.5591 23.999V23.3323L11.5512 24.6656L11.5591 23.999ZM5.65836 17.1828V16.5161H4.99169V17.1828H5.65836ZM17.5866 17.1828V17.8495H18.2533L18.2533 17.1828L17.5866 17.1828ZM5.65836 17.2324L4.9917 17.2366L6.32502 17.2324H5.65836ZM11.6857 23.3323H11.5591V24.6657H11.6857V23.3323ZM11.6224 23.3327C11.6039 23.3327 11.5855 23.3326 11.567 23.3324L11.5512 24.6656C11.5749 24.6659 11.5986 24.6661 11.6224 24.6661V23.3327ZM11.6778 23.3324C11.6593 23.3326 11.6409 23.3327 11.6224 23.3327V24.6661C11.6462 24.6661 11.6699 24.6659 11.6936 24.6656L11.6778 23.3324ZM5.65836 17.8495H17.5866V16.5161H5.65836V17.8495ZM6.32502 17.2324V17.1828H4.99169V17.2324H6.32502ZM6.32501 17.2283C6.32492 17.2133 6.32487 17.1982 6.32487 17.1832H4.99154C4.99154 17.201 4.99159 17.2188 4.9917 17.2366L6.32501 17.2283ZM6.32487 17.1832C6.32487 13.7005 8.77749 11.0336 11.6224 11.0336V9.70028C7.87944 9.70028 4.99154 13.1369 4.99154 17.1832H6.32487ZM11.6224 11.0336C14.4672 11.0336 16.9198 13.7003 16.9199 17.1828L18.2533 17.1828C18.2531 13.1367 15.3652 9.70028 11.6224 9.70028V11.0336Z"
          mask="url(#path-2-inside-1)"/>
    <circle class="figure" cx="11.6215" cy="7.81092" r="3.92681" stroke-width="0.666667"/>
    <path class="figure"
          d="M11.1858 20.6437C11.1868 20.7457 11.2531 20.8384 11.3571 20.8831L11.1858 20.6437ZM11.1858 20.6437L11.1749 19.5361L11.1858 20.6437ZM11.5249 19.5327L11.5215 19.1861H11.1749H10.6179C9.98653 19.1861 9.54824 18.7407 9.54824 18.2816V14.0397C9.54824 13.5807 9.98656 13.1352 10.6179 13.1352H17.7301C18.3615 13.1352 18.7997 13.5807 18.7996 14.0396V14.0397V18.2816C18.7996 18.7406 18.3614 19.1861 17.7299 19.1861H13.6084H13.5004L13.4112 19.247L11.5347 20.527L11.5249 19.5327ZM11.5358 20.6401C11.5358 20.6402 11.5358 20.6402 11.5358 20.6403L11.5358 20.6402L11.5358 20.6401Z"
          stroke-width="0.7"/>
  </svg>

</template>

<script>
import Vue from 'vue';

const IconSpeaker = Vue.extend({
  name: 'icon-speaker',
  props: {
    color: {
      type: String,
      default: '#000',
    },
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
});
export default IconSpeaker;
</script>

<style scoped lang="scss">
.svg-icon {
  fill: none;

  .circle {
    stroke: $ew-black;
  }

  .figure {
    fill: $ew-white;
    stroke: $ew-black;
  }

  .figure2 {
    fill: $ew-white;
    stroke: $ew-black;
  }

  &:hover {
    .circle {
      fill: $ew-blue;
      stroke: $ew-white;
    }

    .figure {
      fill: $ew-blue;
      stroke: $ew-white;
    }

    .figure2 {
      stroke: none;
      fill: $ew-white;
    }
  }
}

.svg-icon__active {
  fill: none;

  .circle {
    fill: $ew-blue;
    stroke: $ew-white;
  }

  .figure {
    fill: $ew-blue;
    stroke: $ew-white;
  }

  .figure2 {
    stroke: none;
    fill: $ew-white;
  }
}
</style>

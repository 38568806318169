<template>
  <svg class="svg-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="circle" d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" stroke-width="0.977778"/>
    <path class="figure" d="M18.5797 12.5289C18.5107 11.0139 17.9032 9.5034 16.7257 8.3454C15.5437 7.1724 13.9987 6.5769 12.4552 6.5109L12.3697 5.6499C14.1577 5.7564 15.8992 6.4509 17.2687 7.7964C18.6412 9.1449 19.3582 10.8489 19.4647 12.6084L18.5797 12.5139V12.5289ZM17.4157 12.6999L16.5622 12.5814C16.5757 11.5299 16.1827 10.4664 15.3667 9.6639C14.5657 8.8779 13.5082 8.4909 12.4567 8.4894L12.3022 7.6449C13.6027 7.6764 14.9032 8.1714 15.8947 9.1449C16.9057 10.1379 17.4007 11.3994 17.4157 12.6999ZM15.3562 12.6489L14.5987 12.4389C14.6872 11.9034 14.4022 11.4339 13.9807 11.0199C13.5982 10.6449 13.0837 10.4754 12.5842 10.5129L12.2917 9.6729C13.0912 9.6219 13.9072 9.8949 14.5177 10.4949C15.1207 11.0874 15.3997 11.8764 15.3562 12.6489ZM13.7512 12.1839C13.7512 12.7284 13.3012 13.1709 12.7462 13.1709C12.5542 13.1709 12.3772 13.1154 12.2242 13.0239L11.7922 13.4499L14.9197 16.4859C14.8432 16.5759 14.7622 16.6674 14.6707 16.7574C12.9172 18.4779 10.0747 18.4779 8.3197 16.7574C6.5662 15.0354 6.5662 12.2454 8.3197 10.5249C8.3992 10.4454 8.4802 10.3794 8.5597 10.3119L11.5057 13.1724L11.9287 12.7569C11.8102 12.5949 11.7397 12.3984 11.7412 12.1854C11.7412 11.6409 12.1912 11.1984 12.7477 11.1984C13.3012 11.1984 13.7512 11.6394 13.7512 12.1839ZM9.9142 18.0729C9.8767 18.0729 10.9357 20.3499 10.9357 20.3499H6.5332L8.0512 16.8324C8.0527 16.8309 8.5132 17.5044 9.9142 18.0729Z"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconBroadcast = Vue.extend({
  name: 'icon-broadcast',
  props: {
    color: {
      type: String,
      default: '#000',
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
});
export default IconBroadcast;
</script>
<style scoped lang="scss">
.svg-icon {
  .circle {
    stroke: $ew-black;
  }

  .figure {
    fill: $ew-black;
  }

  &:hover {
    .circle {
      //fill: $ew-blue;
      stroke: $ew-blue;
    }

    .figure {
      fill: $ew-blue;
    }
  }
}
</style>
